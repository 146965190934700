var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增","width":800,"confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    { required: true, message: '请输入名称' },
                    { max: 64, message: '超出最大长度(64)' },
                  ],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    { required: true, message: '请输入名称' },\n                    { max: 64, message: '超出最大长度(64)' },\n                  ],\n                },\n              ]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]),expression:"['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"权限","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('PermissionCheckbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['permission_set', { initialValue: [] }]),expression:"['permission_set', { initialValue: [] }]"}]})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }